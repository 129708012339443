// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Custom styling
@import 'custom';

// Circular progress styling
@import 'react-circular-progressbar/dist/styles.css'