.input-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: black;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #ebebeb;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    &:focus {
        border-color: #c94141;
        box-shadow: 0 0 0 1px #c94141;
        outline: 0;
    }
    &:hover:enabled {
        box-shadow: 0 0 0 1px #c94141;
    }
    &:disabled {
      background-color: #F3F3F3;
    }
}